import React from 'react'
import loadable from '@loadable/component'
import Cookies from '../components/cookies/cookies'
import Layout from '../layouts/index'
import { cookiePageContent, } from '../utils/informations'

const SecondaryPagesHeader = loadable(() => import('../components/secondary-pages-header/secondary-pages-header'))



const CookiePolicy = () => {

  return (
    <Layout pageTitle="Privacy and Cookie Policy">
      <SecondaryPagesHeader title={'Privacy and Cookie Policy'} />
        <Cookies props={cookiePageContent} cookie={true}/>
    </Layout>
  )

}

export default CookiePolicy